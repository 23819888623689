<template>
  <div :class="['homePage', 'page-' + $t(`message.lang`)]">
    <BackToTop :visible="backShow" @click="toTop" />
    <div class="modal" v-show="companyInfoShow">
      <div class="mask" @click="closeModal"></div>
      <i class="close el-icon-circle-close" @click="closeModal"></i>
      <div class="modal-body">
        <div
          :class="['box', handleClass]"
          :style="{ backgroundImage: 'url(' + nowCompany.bg + ')' }"
        >
          <div class="title">
            <img
              src="https://www.pangodgroup.com/static/point_pro.png"
              class="point"
            />
            <div class="name">{{ $t(nowCompany.business) }}</div>
            <img
              src="https://www.pangodgroup.com/static/point_pro.png"
              class="point"
            />
          </div>
          <div class="wrap">
            <img :src="nowCompany.banner" class="logos" />
            <div class="content">
              <div class="desc">{{ $t(nowCompany.desc) }}</div>
              <div class="bottom">
                <img
                  :src="nowCompany.logo"
                  :alt="$t(nowCompany.business)"
                  class="logo"
                />
                <a
                  :href="nowCompany.href"
                  target="_blank"
                  rel="nofollow"
                  class="more"
                  v-if="nowCompany.href"
                >
                  {{ $t("message.business.hrefTip") }}
                  <i class="el-icon-arrow-right"></i>
                </a>
                <!-- <div class="more" v-else>
                  {{ $t("message.business.noHrefTip") }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="swiper">
      <div class="swiper-wrapper">
        <div class="banner swiper-slide">
          <top-box />
          <div class="content">
            <div
              :class="{
                fadeInUp: true,
                'title-en': $t(`message.lang`) == 'en',
              }"
            >
              <div class="title">
                {{ $t("message.banner.title") }}
              </div>
              <div class="title shadow">
                {{ $t("message.banner.title") }}
              </div>
            </div>
            <img
              src="https://www.pangodgroup.com/static/technology_line.png"
              class="line"
            />
            <div
              :class="{
                'sub-box': true,
                fadeInUp: true,
                'sub-en': $t(`message.lang`) == 'en',
              }"
            >
              <div class="sub">
                {{ $t("message.banner.sub") }}
              </div>
              <div class="sub sub-shadow">
                {{ $t("message.banner.sub") }}
              </div>
            </div>
          </div>
          <Mouse />
        </div>
        <div class="box1 swiper-slide">
          <top-box />
          <div class="title fadeInUp">{{ $t("message.box.introTitle") }}</div>
          <div
            :class="{
              desc: true,
              fadeInUp: true,
              'desc-en': $t(`message.lang`) == 'en',
            }"
          >
            <span>
              {{ $t("message.box.introDesc") }}
            </span>
          </div>
          <Mouse theme="black" />
        </div>
        <div class="box2 swiper-slide">
          <top-box theme="white" />
          <div :class="{ intro: true, 'intro-en': $t(`message.lang`) == 'en' }">
            <div class="title">
              <div class="name fadeIn">
                {{ $t("message.box.generalTitle") }}
              </div>
              <div class="line"></div>
            </div>
            <div class="desc fadeInUp swiper-no-swiping" data-noMouse="true">
              {{ $t("message.box.generalDesc") }}
            </div>
          </div>
          <Mouse />
        </div>
        <div class="box3 swiper-slide">
          <top-box @change="langChange" />
          <div class="title fadeInUp">
            <div class="name">{{ $t("message.box.strengthTitle") }}</div>
            <div class="line"></div>
          </div>
          <div :class="{ wrap: true, en: $t(`message.lang`) == 'en' }">
            <div
              class="item fadeInUp"
              v-for="(item, idx) in strength"
              :key="$t(item.label)"
            >
              <div class="top">
                <div class="left">
                  <div :class="['num', item.topUnit ? 'topUnit' : '']">
                    <div class="count">
                      <countTo
                        :ref="'count' + (idx + 1)"
                        :startVal="0"
                        :endVal="item.num"
                        :duration="3000"
                        :autoplay="false"
                        separator=""
                      ></countTo>
                    </div>
                    <span>{{ $t(item.unit) }}</span>
                    <span
                      class="unit2"
                      v-if="item.unit2 && $t(`message.lang`) == 'zh'"
                      >{{ item.unit2 }}</span
                    >
                  </div>
                  <div class="label">{{ $t(item.label) }}</div>
                </div>
                <img :src="item.icon" class="right" />
              </div>
              <div class="bottom" data-noMouse="true">
                {{ $t(item.desc) }}
              </div>
            </div>
          </div>
          <Mouse />
        </div>
        <div class="box4 swiper-slide">
          <top-box />
          <div class="in">
            <div class="title fadeInUp">
              <div class="name">{{ $t("message.business.title") }}</div>
              <div class="line"></div>
            </div>
            <div class="wrapper">
              <div class="wrap">
                <div
                  class="item"
                  v-for="(item, idx) in company"
                  :key="item.business"
                >
                  <div class="item-in">
                    <img
                      :src="item.logo"
                      :alt="$t(item.business)"
                      class="logo"
                    />
                    <div class="business">
                      <img
                        src="https://www.pangodgroup.com/static/point.png"
                        class="point"
                      />
                      <div class="name">{{ $t(item.business) }}</div>
                      <img
                        src="https://www.pangodgroup.com/static/point.png"
                        class="point"
                      />
                    </div>
                    <div class="infoes" data-noMouse="true">
                      <div class="info" v-for="item in item.info" :key="item">
                        {{ $t(item) }}
                      </div>
                    </div>
                    <div class="more" @click="viewInfo(idx)">
                      {{ $t("message.business.more") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Mouse theme="black" />
        </div>
        <div class="box11 swiper-slide">
          <top-box theme="white" />
          <div class="content">
            <div class="item fadeInRight">
              <div class="title">{{ $t("message.mission.mission1Title") }}</div>
              <div class="line"></div>
              <div class="subs">
                <div class="sub">{{ $t("message.mission.mission1desc1") }}</div>
                <div class="sub">{{ $t("message.mission.mission1desc2") }}</div>
              </div>
            </div>
            <div class="item fadeInRight second">
              <div class="title">{{ $t("message.mission.mission2Title") }}</div>
              <div class="line"></div>
              <div class="subs">
                <div class="sub">{{ $t("message.mission.mission2desc1") }}</div>
                <div class="sub">{{ $t("message.mission.mission2desc2") }}</div>
              </div>
            </div>
            <div class="item fadeInRight third">
              <div class="title">{{ $t("message.mission.mission3Title") }}</div>
              <div class="line"></div>
              <div class="subs">
                <div class="sub">{{ $t("message.mission.mission3desc1") }}</div>
                <div class="sub">{{ $t("message.mission.mission3desc2") }}</div>
                <div class="sub">{{ $t("message.mission.mission3desc3") }}</div>
              </div>
            </div>
          </div>
          <Mouse />
        </div>
        <div class="box12 swiper-slide">
          <top-box />
          <div class="title fadeInUp">
            <div class="name">{{ $t("message.box.cooperationTitle") }}</div>
            <div class="line"></div>
          </div>
          <div class="content fadeInUp">
            <img
              src="https://www.pangodgroup.com/static/bg4_1.png"
              class="out top-left"
            />
            <video
              ref="video"
              poster="https://www.pangodgroup.com/static/video_poster.png"
              src="https://www.pangodgroup.com/static/cooperation_video.mp4"
              controls
              muted="muted"
            ></video>
            <img
              src="https://www.pangodgroup.com/static/bg4_1.png"
              class="out bottom-right"
            />
          </div>
          <Mouse theme="black" />
        </div>
        <div class="box13 swiper-slide">
          <top-box theme="white" />
          <div class="content">
            <div class="map-box">
              <div id="map" class="swiper-no-swiping" data-noMouse="true"></div>
              <img
                :src="
                  'https://www.pangodgroup.com/static/area_' +
                  $t(`message.lang`) +
                  '.jpg'
                "
                alt="区域分布"
                class="area"
              />
            </div>
            <div class="map-info">
              <div class="right fadeInRight">
                <div class="title">{{ $t("message.contact.title") }}</div>
                <div class="line"></div>
              </div>
              <div class="left">
                <div class="addr fadeInLeft">
                  <img
                    src="https://www.pangodgroup.com/static/box13_1.png"
                    class="icon"
                  />
                  <div class="info swiper-no-swiping">
                    <div class="label">
                      {{ $t("message.contact.addrTitle") }}
                    </div>
                    <div class="detail">
                      {{ $t("message.contact.addrDesc") }}
                    </div>
                  </div>
                </div>
                <div class="addr fadeInLeft">
                  <a href="tel:400-6222-028">
                    <img
                      src="https://www.pangodgroup.com/static/box13_3.png"
                      class="icon"
                    />
                  </a>
                  <div class="info swiper-no-swiping">
                    <div class="label">
                      {{ $t("message.contact.phoneTitle") }}
                    </div>
                    <div class="detail">400-6222-028</div>
                  </div>
                </div>
                <div class="addr fadeInLeft">
                  <a href="mailto:market@pangod.com">
                    <img
                      src="https://www.pangodgroup.com/static/box13_2.png"
                      class="icon"
                    />
                  </a>
                  <div class="info swiper-no-swiping">
                    <div class="label">
                      {{ $t("message.contact.emailTitle") }}
                    </div>
                    <div class="detail">market@pangod.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div class="foot swiper-no-swiping">
              <div>{{ $t("message.bottom.copyText") }}</div>
              <div>
                {{ $t("message.bottom.icp") }}：
                <a
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                  rel="nofollow"
                  >蜀ICP备19031371号-1</a
                >
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div :class="['swiper-pagination', 'active' + swiperActiveIdx]">
        <el-tooltip
          :content="$t(item)"
          placement="left"
          v-for="(item, idx) in swiperMap"
          :key="idx"
          :effect="handleTooltipTheme"
        >
          <div
            :class="{
              'my-bullet': true,
              'my-bullet-active': swiperActiveIdx === idx,
            }"
            @click="changeSwiper(idx)"
          ></div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import BackToTop from "../components/backToTop.vue";
import TopBox from "../components/topBox.vue";
import Mouse from "../components/mouseTip.vue";
import underscore from "underscore";
export default {
  data() {
    return {
      languages: [
        { label: "中文", value: "zh" },
        { label: "English", value: "en" },
        { label: "日本語", value: "ja" },
      ],
      strength: [
        {
          num: 22,
          unit: "message.box.unit",
          label: "message.box.strength1Label",
          icon: "https://www.pangodgroup.com/static/box3_1.png",
          desc: "message.box.strength1Desc",
        },
        {
          num: 300,
          unit: "+",
          unit2: "人",
          label: "message.box.strength2Label",
          icon: "https://www.pangodgroup.com/static/box3_2.png",
          desc: "message.box.strength2Desc",
          topUnit: true,
        },
        {
          num: 7900,
          unit: "㎡",
          label: "message.box.strength3Label",
          icon: "https://www.pangodgroup.com/static/box3_3.png",
          desc: "message.box.strength3Desc",
        },
        {
          num: 50000,
          unit: "+",
          label: "message.box.strength4Label",
          icon: "https://www.pangodgroup.com/static/box3_4.png",
          desc: "message.box.strength4Desc",
          topUnit: true,
        },
      ],
      company: [
        {
          id: 1,
          business: "message.business.pangu.title",
          logo: "https://www.pangodgroup.com/static/logo_pangu.png",
          info: [
            "message.business.pangu.info1",
            "message.business.pangu.info2",
            "message.business.pangu.info4",
            "message.business.pangu.info3",
            "message.business.pangu.info5",
            "message.business.pangu.info6",
            "message.business.pangu.info7",
          ],
          banner: "https://www.pangodgroup.com/static/bg5_1.png",
          bg: "https://www.pangodgroup.com/static/bg5.png",
          desc: "message.business.pangu.desc",
          href: "http://www.pangod.com",
        },
        // {
        //   id: 4,
        //   business: "message.business.hongjun.title",
        //   logo: "https://www.pangodgroup.com/static/logo_hongjun.png",
        //   info: [
        //     "message.business.hongjun.info1",
        //     "message.business.hongjun.info2",
        //     "message.business.hongjun.info3",
        //     "message.business.hongjun.info4",
        //   ],
        //   banner: "https://www.pangodgroup.com/static/bg8_1.png",
        //   bg: "https://www.pangodgroup.com/static/bg8.png",
        //   desc: "message.business.hongjun.desc",
        //   href: "http://highdreambrand.com/",
        // },
        {
          id: 2,
          business: "message.business.fangfa.title",
          logo: "https://www.pangodgroup.com/static/logo_fangfa.png",
          info: [
            "message.business.fangfa.info1",
            "message.business.fangfa.info2",
            "message.business.fangfa.info3",
          ],
          banner: "https://www.pangodgroup.com/static/bg6_1.png",
          bg: "https://www.pangodgroup.com/static/bg6.png",
          desc: "message.business.fangfa.desc",
          href: "https://www.fangfa.net/",
        },
        {
          id: 3,
          business: "message.business.diandang.title",
          logo: "https://www.pangodgroup.com/static/logo_diandang.png",
          info: [
            "message.business.diandang.info1",
            "message.business.diandang.info2",
            "message.business.diandang.info3",
          ],
          banner: "https://www.pangodgroup.com/static/bg7_1.png",
          bg: "https://www.pangodgroup.com/static/bg7.png",
          desc: "message.business.diandang.desc",
          href: "http://www.fududiandang.com",
        },
        {
          id: 5,
          business: "message.business.riben.title",
          logo: "https://www.pangodgroup.com/static/logo_riben.png",
          info: [
            "message.business.riben.info1",
            "message.business.riben.info2",
            "message.business.riben.info3",
          ],
          banner: "https://www.pangodgroup.com/static/bg9_1.png",
          bg: "https://www.pangodgroup.com/static/bg9.png",
          desc: "message.business.riben.desc",
          href: "http://www.pangod.jp",
        },
        {
          id: 6,
          business: "message.business.huanzong.title",
          logo: "https://www.pangodgroup.com/static/logo_huanzong.png",
          info: [
            "message.business.huanzong.info1",
            "message.business.huanzong.info2",
            "message.business.huanzong.info3",
            "message.business.huanzong.info4",
            "message.business.huanzong.info5",
          ],
          banner: "https://www.pangodgroup.com/static/bg10_1.png",
          bg: "https://www.pangodgroup.com/static/bg10.png",
          desc: "message.business.huanzong.desc",
        },
      ],
      nowCompany: {},
      swiper: null,
      companyInfoShow: false,
      numIncrease: false, // 判断数字是否已开启过增长
      backShow: false,
      map: null,
      swiperActiveIdx: 0,
      swiperMap: [
        "message.sideMap.map1",
        "message.sideMap.map2",
        "message.sideMap.map3",
        "message.sideMap.map4",
        "message.sideMap.map5",
        "message.sideMap.map6",
        "message.sideMap.map7",
        "message.sideMap.map8",
      ],
      throttleTimeout: false,
    };
  },
  components: { countTo, BackToTop, TopBox, Mouse },
  computed: {
    handleClass() {
      if (this.nowCompany.id % 2 === 0) {
        return "wrap-right";
      }
      return "wrap-left";
    },
    // 不同页面使用不同主题的提示框
    handleTooltipTheme() {
      return [1, 2, 3, 5, 7].includes(this.swiperActiveIdx) ? "light" : "dark";
    },
  },
  created() {
    this.addMouseListener();
  },
  mounted() {
    this.initMap();
    setTimeout(() => {
      this.initPageSwiper();
    }, 100);
  },
  methods: {
    initMap() {
      const centerPath = [104.110687, 30.634132];
      let AMap = window.AMap;
      this.map = new AMap.Map("map", {
        zoom: 16, // 初始化地图层级
        center: centerPath, // 初始化地图中心点
      });
      // 地图控件
      AMap.plugin(["AMap.ToolBar", "AMap.Scale"], () => {
        this.map.addControl(new AMap.ToolBar());
        this.map.addControl(new AMap.Scale());
      });
      const marker = new AMap.Marker({
        position: centerPath,
      });
      this.map.add(marker);
    },
    // 添加鼠标监听事件
    addMouseListener() {
      if (navigator.userAgent.indexOf("Firefox") != -1) {
        // 火狐兼容
        window.addEventListener(
          "DOMMouseScroll",
          underscore.throttle(this.wheelChange, 1000, { trailing: false })
        );
        return;
      }
      window.addEventListener(
        "mousewheel",
        underscore.throttle(this.wheelChange, 1000, { trailing: false })
      );
    },
    // 移除监听
    removeEventListener() {
      window.removeEventListener("mousewheel", this.wheelChange);
      window.removeEventListener("DOMMouseScroll", this.wheelChange);
    },
    // 循环获取当前node以及其父辈元素是否含有data-noMouse属性
    getDropNode(node) {
      while (node) {
        if (node.tagName == "HTML") {
          // 到HTML终止循环
          return false;
        }
        if (node.dataset.nomouse) {
          return true;
        }
        node = node.parentNode;
      }
    },
    // 鼠标滚轮监听事件
    wheelChange(e) {
      const noMouseRule1 = this.isSwiperMovin; // 正在滚动中
      const noMouseRule2 = this.companyInfoShow; // 业务版图详情弹窗中
      const ele = e.target;
      const noMouseRule3 = this.getDropNode(ele); // 可能存在滚动条的div和地图中
      if (noMouseRule1 || noMouseRule2 || noMouseRule3) {
        // 若满足以上条件情况下不做滚动操作
        return;
      }
      let data = 0;
      if (typeof e.deltaY !== "number") {
        // 火狐兼容
        data = e.detail;
      } else {
        data = e.deltaY;
      }
      if (data > 0) {
        // 上滑
        this.swiper.slideNext();
      } else {
        // 下滑
        this.swiper.slidePrev();
      }
    },
    // 返回第一页
    toTop() {
      this.swiper.slideTo(0);
    },
    initPageSwiper() {
      let vm = this;
      this.swiper = new window.Swiper("#swiper", {
        direction: "vertical",
        // pagination: {
        //   el: ".swiper-pagination",
        //   bulletClass: "my-bullet",
        //   bulletActiveClass: "my-bullet-active",
        //   clickable: true,
        // },
        speed: 1000,
        noSwiping: true,
        on: {
          slideChangeTransitionEnd() {
            vm.swiperActiveIdx = this.activeIndex;
            vm.backShow = this.activeIndex != 0;
            if (this.activeIndex == 3) {
              // 开始数字滚动
              for (let i = 0; i < vm.strength.length; i++) {
                vm.$refs["count" + (i + 1)][0].start();
              }
            }
            if (this.activeIndex == 6) {
              vm.$refs.video.play();
            } else {
              vm.$refs.video && vm.$refs.video.pause();
            }
          },
        },
      });
    },
    changeSwiper(idx) {
      if (idx === this.swiperActiveIdx) return;
      this.swiper.slideTo(idx);
    },
    // 查看公司业务详情
    viewInfo(idx) {
      this.nowCompany = this.company[idx];
      this.companyInfoShow = true;
    },
    closeModal() {
      this.companyInfoShow = false;
    },
    // 实力证明页修改语言时重新出发count计数
    langChange() {
      if (this.swiperActiveIdx == 3) {
        setTimeout(() => {
          // 开始数字滚动
          for (let i = 0; i < this.strength.length; i++) {
            this.$refs["count" + (i + 1)][0].start();
          }
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}
@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
.modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  .close {
    font-size: 50px;
    color: #fff;
    position: absolute;
    top: 2vh;
    right: 2vw;
    animation: scaleIn 0.1s linear;

    &:hover {
      color: #e3792a;
      cursor: pointer;
    }
  }

  .modal-body {
    width: 90vw;
    height: 90vh;
    overflow: auto;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 4px;
    animation: scaleIn 0.1s linear;
  }
}
.page-en {
  .box1 .desc {
    text-indent: 0;
  }
  .box2 .intro-en .desc {
    text-indent: 0;
  }
  .box3 .title {
    font-size: 0.36rem;
    left: 5%;
    letter-spacing: 1px;
  }
  .wrap-left,
  .wrap-right {
    .wrap .content .desc {
      letter-spacing: 1px;
      text-indent: 0;
    }
  }
  .box11 .content .item {
    letter-spacing: 1px;
  }
}
.page-ja {
  .banner .content {
    font-size: 0.5rem;
  }
  .box1 .desc,
  .box2 .intro .desc {
    text-indent: 1em;
  }
  .wrap-left,
  .wrap-right {
    .wrap .content .desc {
      text-indent: 1em;
    }
  }
}

#swiper {
  min-width: 1000px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .swiper-pagination {
    text-align: center;
    transition: 0.3s opacity;
    z-index: 10;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);

    &:hover {
      .my-bullet {
        width: 5px;
      }
    }
    .my-bullet {
      width: 3px;
      height: 30px;
      border-radius: 13px;
      background-color: #000;
      opacity: 0.2;
      margin: 6px 0;
      cursor: pointer;
      display: block;
    }
    .my-bullet-active {
      background-color: #007aff !important;
      opacity: 1;
    }
  }

  .active1,
  .active2,
  .active3,
  .active5,
  .active7 {
    .my-bullet {
      background-color: #fff;
      opacity: 1;
    }
  }
}

.banner {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-image: url("https://www.pangodgroup.com/static/banner.png");
  background-size: cover;

  .content {
    width: 70%;
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    margin: auto;
    font-size: pxToRem(32);
    color: #fff;
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(44, 127, 224, 0.38),
      transparent
    );
    padding: 30px 0 30px;
    letter-spacing: 8px;
    position: relative;

    .title {
      color: transparent;
      background-image: linear-gradient(
        337deg,
        #fff 38%,
        rgba(246, 164, 86, 0.8) 51%,
        #fff 60%
      );
      background-clip: text;
      -webkit-background-clip: text;
      position: relative;
      z-index: 4;
    }
    .shadow {
      width: 100%;
      color: #333;
      opacity: 0.2;
      text-align: center;
      background-image: none;
      position: absolute;
      left: 2px;
      top: 5px;
      z-index: -1;
    }
    .title-en .title {
      font-size: pxToRem(22.5);
      line-height: 1.3;
      letter-spacing: 3px;
    }
    .sub-box {
      transition-delay: 0.5s;
    }
    .sub {
      color: transparent;
      background-image: repeating-linear-gradient(
        337deg,
        #fff 10%,
        rgba(246, 164, 86, 0.8) 22%,
        #fff 60%,
        #fff 80%
      );
      background-clip: text;
      -webkit-background-clip: text;
    }
    .sub-shadow {
      width: 100%;
      color: #333;
      opacity: 0.2;
      text-align: center;
      background-image: none;
      position: absolute;
      left: 2px;
      bottom: -5px;
      z-index: -1;
    }
    .sub-en .sub {
      font-size: pxToRem(22.5);
      line-height: 1.1;
      letter-spacing: 3px;
    }
  }

  .banner-title {
    width: 60%;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

.box1 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg1.png");
  background-size: 100% 100%;
  position: relative;
  color: #fff;

  .title {
    width: 1400px;
    font-size: pxToRem(19);
    color: #03162e;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    top: 59%;
    z-index: 5;
    margin: auto;

    &::after {
      content: "";
      width: 60px;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: -30px;
      background-color: #03162e;
    }
  }

  .desc {
    width: 1400px;
    color: #696868;
    font-size: pxToRem(12.5);
    letter-spacing: 2px;
    line-height: 40px;
    text-indent: 2em;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 70px;
    z-index: 5;
    margin: auto;
    text-align: justify;
    transition-delay: 0.5s;

    .btn {
      width: fit-content;
      min-width: 125px;
      max-width: 185px;
      height: 46px;
      line-height: 46px;
      font-size: 14px;
      margin-left: auto;
      text-align: center;
      position: relative;

      &:hover {
        cursor: pointer;

        .top {
          width: 100%;
        }
        .left,
        .right {
          height: 100%;
        }
        .in {
          margin-left: 0;
        }
      }

      .top,
      .bottom {
        width: 100%;
        height: 1px;
        position: absolute;
        right: 0;
        background-color: #bebcbc;
        transition: 0.5s;
      }
      .top {
        top: 0;
        width: 50%;
      }
      .bottom {
        bottom: 0;
      }
      .left,
      .right {
        width: 1px;
        height: 0;
        position: absolute;
        background-color: #bebcbc;
        transition: 0.5s;
      }
      .left {
        top: 0;
        left: 0;
      }
      .right {
        bottom: 0;
        right: 0;
      }

      .in {
        height: 100%;
        margin-left: 33px;
        transition: 0.5s;
        color: #285a97;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .desc-en {
    font-size: pxToRem(10);
    line-height: 33px;
    text-align: left;
    letter-spacing: 1px;
  }
}

.box2 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg2.png");
  background-size: cover;
  position: relative;
  color: #fff;

  .intro {
    width: 700px;
    padding: 40px 50px;
    box-sizing: border-box;
    background-color: rgba($color: #fff, $alpha: 0.96);
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);

    .title {
      color: #34629f;
      font-size: pxToRem(19);
      font-weight: bold;
      letter-spacing: 2px;

      .line {
        width: 80px;
        height: 3px;
        background-color: #34629f;
        margin-top: 20px;
      }
    }
    .desc {
      max-height: 55vh;
      overflow: auto;
      margin-top: 40px;
      text-align: justify;
      text-indent: 2em;
      color: #4f5355;
      font-size: pxToRem(10);
      line-height: 2;
      letter-spacing: 1px;
      transition-delay: 0.5s;
    }
  }
  .intro-en {
    padding: 30px 50px;

    .desc {
      text-align: left;
      margin-top: 20px;
      font-size: 16px;
      line-height: 33px;
    }
  }
}

.box3 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg3.png");
  background-size: cover;
  position: relative;
  color: #fff;

  &::before {
    content: "";
    width: 574px;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.95);
    position: absolute;
    left: 0;
    top: 0;
  }

  .title {
    color: #34629f;
    font-size: pxToRem(19);
    font-weight: bold;
    position: absolute;
    left: 13%;
    top: 13%;
    letter-spacing: 2px;

    .line {
      width: 80px;
      height: 3px;
      background-color: #34629f;
      margin-top: 20px;
    }
  }

  .wrap {
    width: 1600px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 10px #ddd;

    .item {
      width: 400px;
      padding: 20px pxToRem(25);
      box-sizing: border-box;

      &:nth-child(1) {
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        transition-delay: 0.4s;
      }
      &:nth-child(3) {
        transition-delay: 0.5s;
      }
      &:nth-child(4) {
        transition-delay: 0.6s;
      }

      .top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          background-color: #e3792a;
          width: 20px;
          height: 2px;
        }

        .num {
          margin-top: 20px;
          line-height: 1.2;
          color: #286a97;
          font-size: pxToRem(12);
          letter-spacing: 5px;
          display: flex;
          align-items: flex-end;

          .count {
            font-size: pxToRem(22.5);
          }
        }
        .unit2 {
          align-self: flex-end;
        }
        .topUnit {
          align-items: flex-start;
        }
        .label {
          color: #656667;
          font-size: pxToRem(10);
          margin-top: 10px;
        }
        .right {
          margin-top: 10px;
        }
      }
      .bottom {
        color: #818080;
        margin-top: 30px;
        font-size: 17px;
        line-height: 26px;
        letter-spacing: 1px;
        text-align: justify;
      }
    }
  }
  .en {
    .item {
      .top .label {
        height: 55px;
      }
      .bottom {
        text-align: left;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}
.box4 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg4.png");
  background-size: cover;

  &::before {
    content: "";
    display: table;
  }

  .title {
    color: #34629f;
    font-size: pxToRem(19);
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 70px;

    .line {
      width: 80px;
      height: 3px;
      background-color: #34629f;
      margin: 20px auto 0;
    }
  }

  .wrap {
    height: 600px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .item {
      position: relative;

      .item-in {
        // width: 215px;
        width: 230px;
        height: 451px;
        border: 1px solid #e3792a;
        border-radius: 10px;
        background-color: #fff;
        padding: 15px 20px 22px;
        box-sizing: border-box;
        transition: 0.5s;
        position: relative;
        z-index: 5;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0px 10px 38px 0px rgba(158, 158, 158, 0.8);
        }
      }
      &:nth-child(n + 3)::after {
        content: "";
        width: 266px;
        height: 5px;
        border-top: 8px solid rgba(227, 121, 42, 57%);
        position: absolute;
        left: -266px;
        top: 80px;
        z-index: 1;
      }
      &:nth-child(2n) {
        align-self: flex-end;

        &::after {
          top: unset;
          bottom: 80px;
        }
      }

      & + .item {
        margin-left: 18px;
      }

      .logo {
        height: 40px;
        object-fit: contain;
        margin: 0 auto 30px;
        display: block;
      }
      .business {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .name {
          margin: 0 2px;
          color: #e3792a;
          font-size: 15px;
          letter-spacing: 2px;
          text-align: center;
        }
        .point {
          width: 12px;
        }
      }
      .infoes {
        color: #2d2a2a;
        text-align: center;

        .info {
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
      .more {
        width: 87px;
        height: 33px;
        text-align: center;
        line-height: 33px;
        font-size: 13px;
        color: #fff;
        background-color: #286a97;
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: rgb(45, 73, 123);
        }
      }
    }
  }
}

.wrap-left,
.wrap-right {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    position: absolute;
    left: 13%;
    top: 10%;

    .name {
      color: #e3792a;
      font-size: pxToRem(19.5);
      margin: 0 5px;
      letter-spacing: 2px;
    }

    .point {
      width: 31px;
    }
  }
  .wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    .logos {
      width: 540px;
      position: absolute;
      left: 5%;
      top: 5%;
    }
    .content {
      width: 800px;
      border-radius: 10px;
      box-shadow: 0 0 10px #bbb;
      margin: 0 auto;
      background-color: #fff;
      padding: 66px 70px 40px 185px;
      box-sizing: border-box;

      .desc {
        max-height: 35vh;
        overflow: auto;
        color: #535353;
        font-size: 17px;
        letter-spacing: 3px;
        text-indent: 2em;
        line-height: 33px;
        margin-bottom: 25px;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          width: 195px;
        }
        .more {
          width: 160px;
          height: 50px;
          display: block;
          line-height: 50px;
          color: #fff;
          background-color: #286a97;
          font-size: pxToRem(9);
          letter-spacing: 2px;
          text-align: center;
        }
      }
    }
  }
}
.wrap-right {
  .title {
    left: unset;
    right: 13%;
  }

  .wrap {
    .logos {
      left: unset;
      right: 5%;
    }
    .content {
      padding: 66px 185px 40px 70px;
    }
  }
}

.box11 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg11.png");
  background-size: cover;
  color: #fff;

  .content {
    height: 90%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 10%;

    .item {
      letter-spacing: 3px;
      text-align: right;

      .title {
        font-size: pxToRem(25);
        letter-spacing: 10px;
      }
      .line {
        width: 30px;
        height: 1px;
        background-color: #e3792a;
        margin: 10px 0 20px auto;
      }
      .subs {
        font-size: pxToRem(12.5);
        line-height: 45px;
      }
    }
    .second {
      transition-delay: 0.4s;
    }
    .third {
      transition-delay: 0.7s;
    }
  }
}

.box12 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg4.png");
  background-size: cover;

  &::before {
    content: "";
    display: table;
  }

  .title {
    color: #34629f;
    font-size: pxToRem(19);
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 70px;

    .line {
      width: 80px;
      height: 3px;
      background-color: #34629f;
      margin: 20px auto 0;
    }
  }
  .content {
    width: 1000px;
    margin: 100px auto 0;
    position: relative;

    video {
      width: 100%;
    }

    .out {
      width: 20%;
      position: absolute;
    }
    .top-left {
      left: -35px;
      top: -35px;
    }
    .bottom-right {
      bottom: -30px;
      right: -30px;
      transform: rotate(180deg);
      transform-origin: center;
    }
  }
}

.box13 {
  width: 100%;
  height: 100vh;
  background-image: url("https://www.pangodgroup.com/static/bg13.png");
  background-size: cover;
  position: relative;
  color: #fff;

  .content {
    width: 1000px;
    background-color: #fff;
    border: 4px solid #286a97;
    padding: 45px 50px;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .map-box {
      height: 400px;
      display: flex;

      #map {
        width: 50%;
        height: 100%;
      }

      .area {
        width: 50%;
        height: 100%;
        object-fit: contain;
      }
    }

    .map-info {
      height: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: initial;
    }

    .left {
      max-width: 50%;
      width: fit-content;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .addr {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        &:last-child {
          transition-delay: 0.5s;
        }

        .icon {
          width: 30px;
        }
        .info {
          flex-grow: 1;
          margin-right: 20px;
          text-align: right;

          .label {
            width: 100%;
            padding-bottom: 5px;
            color: #120b0b;
            font-size: pxToRem(11);
            font-weight: bold;
            margin-left: auto;
          }
          .detail {
            padding-top: 5px;
            color: #161212;
            font-size: pxToRem(9);
            border-top: 1px dashed #333;
          }
        }
      }
    }
    .right {
      flex-shrink: 0;
      color: #34629f;
      font-size: pxToRem(18);
      font-weight: bold;

      .line {
        width: 80px;
        height: 2px;
        margin-top: 20px;
        background-color: #34629f;
      }
    }
  }

  footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;

    .foot {
      padding: 10px 0;
      color: #f0efef;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      div + div {
        margin-left: 80px;
      }

      a {
        color: #f0efef;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.fadeInUp {
  transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
  transform: translateY(10px);
  opacity: 0;
}
.fadeIn {
  transition: opacity 0.5s 0.2s;
  opacity: 0;
}
.fadeInRight {
  transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
  transform: translateX(20px);
  opacity: 0;
}
.fadeInLeft {
  transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
  transform: translateX(-20px);
  opacity: 0;
}

.swiper-slide-active {
  .fadeInUp {
    transform: translateY(0);
    opacity: 1;
  }
  .fadeIn {
    opacity: 1;
  }
  .fadeInRight,
  .fadeInLeft {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 1870px) {
  .wrap-left,
  .wrap-right {
    .wrap {
      .logos {
        width: pxToRem(240);
      }
    }
  }
  .box11 .content .item {
    .title {
      font-size: 0.4rem;
    }
    .line {
      margin: 10px 0 10px auto;
    }
    .subs {
      line-height: 36px;
    }
  }
}
@media (max-width: 1750px) {
  .wrap-left,
  .wrap-right {
    .wrap {
      .content .desc {
        font-size: 15px;
      }
      .logos {
        width: 4.5rem;
      }
    }
  }
}
@media (max-width: 1650px) {
  .box1 {
    .title {
      width: 80vw;
    }
    .desc {
      width: 80vw;
      line-height: 32px;
      bottom: 50px;

      .btn {
        height: 38px;
        line-height: 38px;
        min-width: 90px;
      }
    }
    .desc-en {
      line-height: 25px;
    }
  }
  .box2 .intro {
    width: pxToRem(350);
    padding: 20px 30px;
  }
  .box3 .wrap {
    width: calc(100vw - 26%);

    .item {
      width: 25%;

      .top {
        .num .count {
          font-size: pxToRem(20);
        }
        .right {
          width: 20px;
        }
      }

      .bottom {
        font-size: 14px;
        max-height: 180px;
        line-height: 22px;
        overflow: auto;
      }
    }
  }
  .box4 {
    .title {
      margin-top: 40px;
    }
    .wrap {
      height: 550px;
      margin-top: 40px;

      .item {
        .item-in {
          // width: 180px;
          width: 200px;
          height: 5rem;
          padding: 15px 15px 20px;
        }
        .logo {
          margin-bottom: 10px;
        }
        .business {
          margin-bottom: 15px;
        }
        .infoes {
          font-size: 14px;
        }
      }
    }
  }
  .box11 .content {
    padding: 0 10%;
  }
  .box12 {
    .title {
      margin-top: 40px;
    }
    .content {
      width: 60%;
      margin-top: pxToRem(40);
    }
  }
  .box13 .content {
    width: 60vw;
    padding: 25px 30px;

    .map-box {
      height: 350px;
    }

    #map {
      height: 300px;
    }

    .map-info {
      height: 230px;
    }

    .left .addr .icon {
      width: 30px;
    }
  }
  .wrap-left,
  .wrap-right {
    .wrap {
      .content {
        width: pxToRem(400);

        .desc {
          font-size: 14px;
          line-height: 21px;
        }
        .bottom {
          .logo {
            width: 140px;
          }
          .more {
            width: 140px;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
  .modal .close {
    font-size: 40px;
  }
}

@media (max-width: 1440px) {
  .banner {
    .content {
      width: 80%;
    }
    .mouse {
      width: 250px;
    }
  }
  .box1 .desc {
    font-size: 17px;
  }
  .box1 .desc-en {
    line-height: 20px;
  }
  .box2 .intro {
    .desc {
      line-height: 25px;
      margin-top: 25px;
      font-size: 14px;
    }
  }
  .box3 .wrap .item {
    .top .label {
      font-size: 0.24rem;
    }
  }
  .box4 {
    .in {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .wrapper {
        height: calc(100% - 85px);
        padding: 30px 0;
        box-sizing: border-box;
      }
    }
    .title {
      .line {
        margin: 10px auto 0;
      }
    }
    .wrap {
      height: 100%;
      max-height: 9.3rem;
      margin-top: 0;

      .item {
        height: 80%;

        &:hover .item-in {
          z-index: 30;
        }
        & + .item {
          margin-left: 5px;
        }
        &:nth-child(n + 3)::after {
          border-width: 6px;
          top: 40px;
        }
        &:nth-child(2n)::after {
          top: unset;
          bottom: 40px;
        }
        .item-in {
          width: 3.3rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .infoes {
          flex-grow: 1;
          overflow: auto;
          margin-bottom: 10px;
        }
        .more {
          position: static;
          transform: none;
        }
      }
    }
  }
  .wrap-left,
  .wrap-right {
    .wrap {
      .content {
        padding: 20px 40px;

        .desc {
          max-height: 47vh;
          margin-bottom: 15px;
        }
        .bottom .more {
          height: 35px;
          line-height: 35px;
        }
      }
      .logos {
        width: 6rem;
      }
    }
  }
  .box11 .content .item {
    .line {
      margin: 5px 0 10px auto;
    }
    .subs {
      line-height: 30px;
    }
  }
  .box12 .title .line {
    margin-top: 10px;
  }
  .box13 .content {
    .map-box {
      height: 300px;
    }
    .left .addr .info .detail {
      font-size: 14px;
    }
  }
}
</style>
