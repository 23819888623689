export const message = {
  lang: "en",
  action: {
    cancel: "cancel",
  },
  sideMap: {
    map1: "Home Page",
    map2: "Explore Pangod Group",
    map3: "Overview",
    map4: "Testimony Of Strength",
    map5: "Business Map",
    map6: "Corporate Culture",
    map7: "Business Partners",
    map8: "Contact Us",
  },
  banner: {
    title: "Focusing on commercial services",
    sub: "Integrating independent operation and investment incubation",
  },
  box: {
    introTitle: "Explore Pangod Group",
    introDesc:
      "We originated from a network company founded in 2003 in Chengdu, called Chengdu Pangod Network Co., Ltd. Focusing on commercial services, Pangod Group is a comprehensive group of companies integrating independent operation and investment incubation.",
    introMore: "MORE",
    viewAll: "View All",
    generalTitle: "Overview",
    generalDesc:
      "We originated from a network company founded in 2003 in Chengdu, called Chengdu Pangod Network Co., Ltd. Focusing on commercial services, Pangod Group is a comprehensive group of companies integrating independent operation and investment incubation. Our offices are located in Sichuan, Chongqing, Shanxi, Guangxi, Beijing and Japan. The main business covers advertising and technical services, financial  services, hotel and travel services, etc.We have successively incubated business and service brands such as Pangod, Pangu Information, Fudu , Pangoo, and Huanzong National Travel. Over the course of 21 years, we have provided advertising and technical services to The  People's Government of Chengdu Municipality, Chengdu Talent Service Center, Administration For Market Rugulation Of Chengdu, Chengdu Meteorological Office, Petro China Southwest Oil & Gasfleld Company, CHINA MCC5 GROUP CORP,LTD, Chengdu Software Industry Development Center, etc.We have provided content services to enterprises and institutions such as the China Academy of Engineering Physics,  Nanshan High School, and Chengdu BOE Hospital, and we have provided  long-term brand promotion services to banks such as BANK OF CHINA, CHINA MINSHENG BANK , and POSTAL SAVINGS BANK OF CHINA. We have also provided provides short-term emergency financial services to a large number of small and medium-sized enterprises .Since 2022, we have started to provide services such as Japanese B&B operation, domestic and outbound travel, customized travel for enterprises and groups, immigration and overseas studies, etc.，establishing a good reputation and popularity in the industry and among customers.",
    unit: "years",
    strengthTitle: "Testimony Of Strength",
    strength1Label: "Extensive Experience",
    strength1Desc:
      "We offer over ten types of online advertising are offered on platforms such as Tencent, Xiaohongshu, Baidu, TikTok and Focus Media, as well as offline advertising services such as Focus Media, access control gates, Suixintui, Additionally, we provide technical services, content services, financial services, hotel and travel services, etc.",
    strength2Label: "Service Team",
    strength2Desc:
      "Offering exceptional expertise in internet advertising and technology services, content services, financial services, and hotel and travel services, ensuring high standards of quality and delivery.",
    strength3Label: "Grade A Offices",
    strength3Desc:
      "Across six provinces in two countries，in the heart of cities across Sichuan, Chongqing, Shanxi, Guangxi, Beijing, and Japan, our modern office spaces are equipped with Apple computers, providing a seamless working environment.",
    strength4Label: "Clients",
    strength4Desc:
      "Involved a diverse range of industries, including government, education, energy, manufacturing, retail, e-commerce, healthcare, catering, logistics, tourism, photography, decoration, home furnishing, beauty, and chemicals.",
    cooperationTitle: "Business Partners",
  },
  business: {
    title: "Business Map",
    more: "MORE",
    pangu: {
      title: "Advertising Business",
      info1: "Search advertising",
      info2: "Native advertising",
      info3: "Offline advertising",
      info4: "E-commerce services",
      info5: "Technical services",
      info6: "Content services",
      info7: "Brand services",
      desc: "Pangod Advertising is a professional solution provider on internet advertising and technology services. Founded in 2003 and based on rich media resources, strong direct sales management capabilities, abundant industry experiences, and good service awareness and capabilities, Pangod Advertising aims to create a service platform, which connects various and rapidly-developing internet media with regional clients of different industries. It helps internet media platforms promote and monetize their advertisements in the regional market, and helps regional clients effectively combine their traditional business models with online marketing to gain unique advantages and improve the return rates on advertising. Over the years, Pangod Advertising has established a good popularity and reputation among clients and the industry.",
    },
    fangfa: {
      title: "Technology Services",
      info1: "Digital customization for major clients",
      info2: "Street/Community SaaS services",
      info3: "IoT service provider for rural revitalization",
      desc: "Sichuan Fangfa Digital Sicence and Technology Co., Ltd. is an innovative digital technology service provider. Founded in 2003, it has been provided professional software development, system integration and e-commerce services to enterprises and organizations. Fangfa has extensive experiences in key sectors such as education, energy, manufacturing, government, retail, and e-commerce. It has been committed to providing professional, comprehensive, and efficient software technology services to the clients. Fangfa has the Software Enterprise Certificate and the Software Product Registration Certificate. It also has ISO9001Certificate for quality management, ISO14001 Certificate for environmental management, OHSAS18001 Certificate for occupational health and safety management, and the Telecommunications Value-Added Service Business License (ICP). In addition, Fangfa is the Executive director unit of the Sichuan E-commerce Association and Chengdu Software Industry Association.",
    },
    diandang: {
      title: "Financial Services",
      info1: "Real estate mortgage pawn",
      info2: "Pledge of financial rights and interests",
      info3: "Pawn of movable property and more",
      desc: "Sichuan Fudu Pawn Co., Ltd. was established in 2009 with a registered capital of RMB eighty million. It is a non-banking financial institution approved by the Sichuan Regulatory Bureau of the National Financial Supervision and Administration Authority, which engages in various types of pledge and mortgage loan businesses. Its main business includes movable property pawn, property rights pawn, and real estate mortgage pawn. Guided by the principles of Integrity-based and Customer-first, Fudu prioritizes service, integrity, and helping people in urgent need. The corporate spirit of Fudu is Dedication, Trustworthiness, Loyalty, Dedication, Exploration, Innovation, Ambition and Strength. Fudu is committed to providing flexible, convenient, and short-term financing services to their customers. It aims to become a trusted “loan bank” for small and medium-sized enterprises and the general public.",
    },
    hongjun: {
      title: "Brand Services",
      info1: "New media operation",
      info2: "Graphic design",
      info3: "Video production",
      info4: "Event planning",
      info5: "Software development",
      desc: "Pangod Advertising is committed to assisting government and corporate clients in brand management, image planning, publicity consulting, public relations activities, while providing services such as advertising design, production, cross-media placement, event execution, software development, etc.",
      desc_back:
        "Chengdu Hongjun Brand Management Co., Ltd. is committed to assisting government and corporate clients in brand management, image planning, publicity consulting, public relations activities, while providing services such as advertising design, production, cross-media placement, event execution, software development, etc.",
    },
    riben: {
      title: "International Business",
      info1: "B&B operation",
      info2: "Hotel management",
      info3: "Travel and residence in Japan",
      desc: "In 2020, BANKO NIHON CO., LTD. was established in Tokyo, Japan. It has been specialized in cross-border tourism and investment services between China and Japan. Based on the Tokyo metropolitan area, it provides services throughout Japan, seeking high-quality properties with unique perspectives and creating benchmark brands for guesthouses and hotels in the Kanto region. It offers elegant and comfortable accommodations for visitors of Japan, ideal investment targets for high-net-worth individuals, and high-quality consulting services for cross-border business clients.",
    },
    huanzong: {
      title: "Travel Services",
      info1: "Domestic tours/Outbound tours",
      info2: "Corporate/Group customized tours",
      info3: "Global visa processing",
      info4: "Machine wine charter car booking",
      info5: "Immigration and study abroad services",
      desc: "Chengdu Huanyu Zongheng International Travel Agency Co., Ltd. was established in 2019 and headquartered in Chengdu, Sichuan Province. It is a full-chain comprehensive tourism service provider that provides route customization, strategy services, global visa application, hotel and air ticket booking, immigration and study abroad services. The business scope covers the whole country and can undertake individual, group tourism, corporate business visits, conferences and other related businesses.",
    },
    hrefTip: "MORE",
    noHrefTip: "Please stay tuned",
  },
  mission: {
    mission1Title: "Mission",
    mission1desc1: "Surpporting the clients' business",
    mission1desc2: "Empowering the employees' lives",
    mission2Title: "Vision",
    mission2desc1:
      "Becoming comprehensive and pursuing excellence in commercial services",
    mission2desc2:
      "Becoming human-oriented and taking on social responsibilities",
    mission3Title: "Values",
    mission3desc1: "Honest, Professional",
    mission3desc2: "Adventurous, Loyal",
    mission3desc3: "Competitive, Enforceable",
  },
  contact: {
    title: "Contact Us",
    addrTitle: "Address",
    addrDesc:
      "37F, IMP Global Center, No.318, Dongda Road, Chengdu, Sichuan Province, China.",
    phoneTitle: "Phone",
    emailTitle: "Email",
  },
  bottom: {
    copyText: "Copyright © 2024 Chengdu Pangod Group Co., Ltd.",
    icp: "File number",
  },
};
