<template>
  <div :class="['homePage', 'page-' + $t(`message.lang`)]">
    <BackToTop :visible="backShow" @click="toTop" />
    <van-action-sheet v-model="langShow">
      <div class="lang-content">
        <div class="select">
          <div
            class="option"
            v-for="item in languages"
            :key="item.value"
            @click="langSelect(item)"
          >
            <div class="in">
              <img class="lang_icon" :src="item.icon" alt="" />
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
        <div class="gap"></div>
        <div class="cancel" @click="langShow = false">
          {{ $t("message.action.cancel") }}
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="mapChoseShow"
      :actions="finalMaps"
      @select="mapSelect"
      cancel-text="取消"
      close-on-click-action
    />
    <div class="modal" v-show="companyInfoShow">
      <div class="mask" @click="closeModal"></div>
      <div class="modal-body">
        <div :class="['box', handleClass]">
          <div class="head">
            <div class="title">
              <img
                src="https://www.pangodgroup.com/static/point_pro.png"
                class="point"
              />
              <div class="name">{{ $t(nowCompany.business) }}</div>
              <img
                src="https://www.pangodgroup.com/static/point_pro.png"
                class="point"
              />
            </div>
            <i class="close el-icon-close" @click="closeModal"></i>
          </div>
          <div class="wrap">
            <img :src="nowCompany.banner" class="logos" />
            <div class="content">
              <div class="desc">{{ $t(nowCompany.desc) }}</div>
              <div class="bottom">
                <img
                  :src="nowCompany.logo"
                  :alt="$t(nowCompany.business)"
                  class="logo"
                />
                <a
                  :href="nowCompany.href"
                  target="_blank"
                  rel="nofollow"
                  class="more"
                  v-if="nowCompany.href"
                >
                  {{ $t("message.business.hrefTip") }}
                  <i class="el-icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal infoModal" v-show="companyDetailShow">
      <div class="mask" @click="closeModal"></div>
      <div class="modal-body">
        <div class="box wrap-left">
          <div class="head">
            <div class="title">
              <img
                src="https://www.pangodgroup.com/static/point_pro.png"
                class="point"
              />
              <div class="name">{{ $t("message.box.generalTitle") }}</div>
              <img
                src="https://www.pangodgroup.com/static/point_pro.png"
                class="point"
              />
            </div>
            <i class="close el-icon-close" @click="closeModal"></i>
          </div>
          <div class="wrap">
            <div class="content">
              <div class="desc">{{ $t("message.box.generalDesc") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="swiper">
      <div class="swiper-wrapper">
        <div class="box1 swiper-slide">
          <img
            src="https://www.pangodgroup.com/static/logo.png"
            alt="网站logo"
            class="page_logo"
          />
          <div class="nowlang" @click="openAction">
            <img class="lang_icon" :src="nowLang.icon" />
            <span>{{ nowLang.label }}</span>
          </div>
          <div class="banner">
            <div class="content">
              <div
                :class="{
                  fadeInUp: true,
                  'title-en': $t(`message.lang`) == 'en',
                }"
              >
                <div class="title">
                  {{ $t("message.banner.title") }}
                </div>
                <div class="title shadow">
                  {{ $t("message.banner.title") }}
                </div>
              </div>
              <img
                src="https://www.pangodgroup.com/static/technology_line.png"
                class="line"
              />
              <div
                :class="{
                  'sub-box': true,
                  fadeInUp: true,
                  'sub-en': $t(`message.lang`) == 'en',
                }"
              >
                <div class="sub">
                  {{ $t("message.banner.sub") }}
                </div>
                <div class="sub sub-shadow">
                  {{ $t("message.banner.sub") }}
                </div>
              </div>
            </div>
          </div>
          <div class="intro">
            <div class="intro-left">
              <div class="title fadeInUp">
                {{ $t("message.box.introTitle") }}
              </div>
              <div
                id="scroll"
                :class="{
                  'swiper-no-swiping': true,
                  desc: true,
                  fadeInUp: true,
                  'desc-en': $t(`message.lang`) == 'en',
                }"
              >
                {{ $t("message.box.generalDesc") }}
              </div>
            </div>
          </div>
        </div>
        <div class="box3 swiper-slide">
          <img
            src="https://www.pangodgroup.com/static/logo.png"
            alt="网站logo"
            class="page_logo"
          />
          <div class="content">
            <div class="title fadeInUp">
              <div class="name">{{ $t("message.box.strengthTitle") }}</div>
              <div class="line"></div>
            </div>
            <div :class="{ wrap: true, en: $t(`message.lang`) == 'en' }">
              <div
                class="item fadeInUp"
                v-for="(item, idx) in strength"
                :key="$t(item.label)"
              >
                <div class="in">
                  <div :class="['num', item.topUnit ? 'topUnit' : '']">
                    <div class="count">
                      <countTo
                        :ref="'count' + (idx + 1)"
                        :startVal="0"
                        :endVal="item.num"
                        :duration="3000"
                        :autoplay="false"
                        separator=""
                      ></countTo>
                    </div>
                    <span>{{ $t(item.unit) }}</span>
                  </div>
                  <div class="line"></div>
                  <div class="label">{{ $t(item.label) }}</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="https://www.pangodgroup.com/static/m/page3_bg2.png"
            class="bottom-bg"
          />
        </div>
        <div class="box4 swiper-slide">
          <img
            src="https://www.pangodgroup.com/static/logo-white.png"
            alt="网站logo"
            class="page_logo"
          />
          <div class="in">
            <div class="title fadeInUp">
              <div class="name">{{ $t("message.business.title") }}</div>
              <div class="line"></div>
            </div>
            <div class="wrap">
              <div
                :class="['item', idx % 2 == 0 ? 'fadeInLeft' : 'fadeInRight']"
                v-for="(item, idx) in company"
                :key="item.business"
              >
                <div class="top">
                  <img :src="item.logo" :alt="$t(item.business)" class="logo" />
                  <div class="more" @click="viewInfo(idx)">
                    {{ $t("message.business.more") }}
                  </div>
                </div>
                <div class="infoes">
                  <div class="info" v-for="item in item.info" :key="item">
                    {{ $t(item) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box11 swiper-slide">
          <img
            src="https://www.pangodgroup.com/static/logo-white.png"
            alt="网站logo"
            class="page_logo"
          />
          <div class="content">
            <div class="item fadeInRight">
              <div class="title">{{ $t("message.mission.mission1Title") }}</div>
              <div class="line"></div>
              <div class="subs">
                <div class="sub">{{ $t("message.mission.mission1desc1") }}</div>
                <div class="sub">{{ $t("message.mission.mission1desc2") }}</div>
              </div>
            </div>
            <div class="item fadeInRight second">
              <div class="title">{{ $t("message.mission.mission2Title") }}</div>
              <div class="line"></div>
              <div class="subs">
                <div class="sub">{{ $t("message.mission.mission2desc1") }}</div>
                <div class="sub">{{ $t("message.mission.mission2desc2") }}</div>
              </div>
            </div>
            <div class="item fadeInRight third">
              <div class="title">{{ $t("message.mission.mission3Title") }}</div>
              <div class="line"></div>
              <div class="subs">
                <div class="sub">{{ $t("message.mission.mission3desc1") }}</div>
                <div class="sub">{{ $t("message.mission.mission3desc2") }}</div>
                <div class="sub">{{ $t("message.mission.mission3desc3") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box12 swiper-slide">
          <img
            src="https://www.pangodgroup.com/static/logo.png"
            alt="网站logo"
            class="page_logo"
          />
          <div class="half">
            <div class="title fadeInUp">
              <div class="name">{{ $t("message.box.cooperationTitle") }}</div>
              <div class="line"></div>
            </div>
            <div class="content fadeInUp">
              <video
                ref="video"
                poster="https://www.pangodgroup.com/static/video_poster.png"
                src="https://www.pangodgroup.com/static/cooperation_video.mp4"
                controls
                loop
              ></video>
            </div>
          </div>
          <div class="contact">
            <!-- <div class="contact-title fadeInUp">
              {{ $t("message.contact.title") }}
            </div> -->
            <footer class="content-box">
              <div class="left">
                <div class="addr fadeInLeft" @click="openMap">
                  <img
                    src="https://www.pangodgroup.com/static/m/location.png"
                    class="icon"
                  />
                  <div class="info">
                    <div class="detail">
                      {{ $t("message.contact.addrTitle") }}：
                      <span>{{ $t("message.contact.addrDesc") }}</span>
                    </div>
                  </div>
                </div>
                <a href="tel:400-6222-028" class="addr fadeInLeft">
                  <img
                    src="https://www.pangodgroup.com/static/m/phone.png"
                    class="icon"
                  />
                  <div class="info">
                    <div class="detail">
                      {{ $t("message.contact.phoneTitle") }}：
                      <span>400-6222-028</span>
                    </div>
                  </div>
                </a>
                <a href="mailto:market@pangod.com" class="addr fadeInLeft">
                  <img
                    src="https://www.pangodgroup.com/static//m/email.png"
                    class="icon"
                  />
                  <div class="info">
                    <div class="detail">
                      {{ $t("message.contact.emailTitle") }}：
                      <span>market@pangod.com</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="foot">
                <div>{{ $t("message.bottom.copyText") }}</div>
                <div>
                  {{ $t("message.bottom.icp") }}：
                  <a
                    href="https://beian.miit.gov.cn/"
                    target="_blank"
                    rel="nofollow"
                    >蜀ICP备19031371号-1</a
                  >
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import BackToTop from "../../components/backToTop.vue";
export default {
  data() {
    return {
      langShow: false,
      languages: [
        {
          label: "CN",
          value: "zh",
          icon: "https://www.pangodgroup.com/static/china.png",
        },
        {
          label: "EN",
          value: "en",
          icon: "https://www.pangodgroup.com/static/english.png",
        },
        {
          label: "JP",
          value: "ja",
          icon: "https://www.pangodgroup.com/static/japanese.png",
        },
      ],
      nowLang: {},
      strength: [
        {
          num: 22,
          unit: "message.box.unit",
          label: "message.box.strength1Label",
          icon: "https://www.pangodgroup.com/static/box3_1.png",
          desc: "message.box.strength1Desc",
        },
        {
          num: 300,
          unit: "+",
          label: "message.box.strength2Label",
          icon: "https://www.pangodgroup.com/static/box3_2.png",
          desc: "message.box.strength2Desc",
          topUnit: true,
        },
        {
          num: 7900,
          unit: "㎡",
          label: "message.box.strength3Label",
          icon: "https://www.pangodgroup.com/static/box3_3.png",
          desc: "message.box.strength3Desc",
        },
        {
          num: 50000,
          unit: "+",
          label: "message.box.strength4Label",
          icon: "https://www.pangodgroup.com/static/box3_4.png",
          desc: "message.box.strength4Desc",
          topUnit: true,
        },
      ],
      company: [
        {
          id: 1,
          business: "message.business.pangu.title",
          logo: "https://www.pangodgroup.com/static/logo_pangu.png",
          info: [
            "message.business.pangu.info1",
            "message.business.pangu.info2",
            "message.business.pangu.info4",
            "message.business.pangu.info3",
            "message.business.pangu.info5",
            "message.business.pangu.info6",
            "message.business.pangu.info7",
          ],
          banner: "https://www.pangodgroup.com/static/bg5_1.png",
          bg: "https://www.pangodgroup.com/static/bg5.png",
          desc: "message.business.pangu.desc",
          href: "http://www.pangod.com",
        },
        // {
        //   id: 4,
        //   business: "message.business.hongjun.title",
        //   logo: "https://www.pangodgroup.com/static/logo_hongjun.png",
        //   info: [
        //     "message.business.hongjun.info1",
        //     "message.business.hongjun.info2",
        //     "message.business.hongjun.info3",
        //     "message.business.hongjun.info4",
        //     "message.business.hongjun.info5",
        //   ],
        //   banner: "https://www.pangodgroup.com/static/bg8_1.png",
        //   bg: "https://www.pangodgroup.com/static/bg8.png",
        //   desc: "message.business.hongjun.desc",
        //   href: "http://highdreambrand.com/",
        // },
        {
          id: 2,
          business: "message.business.fangfa.title",
          logo: "https://www.pangodgroup.com/static/logo_fangfa.png",
          info: [
            "message.business.fangfa.info1",
            "message.business.fangfa.info2",
            "message.business.fangfa.info3",
          ],
          banner: "https://www.pangodgroup.com/static/bg6_1.png",
          bg: "https://www.pangodgroup.com/static/bg6.png",
          desc: "message.business.fangfa.desc",
          href: "https://www.fangfa.net/",
        },
        {
          id: 3,
          business: "message.business.diandang.title",
          logo: "https://www.pangodgroup.com/static/logo_diandang.png",
          info: [
            "message.business.diandang.info1",
            "message.business.diandang.info2",
            "message.business.diandang.info3",
          ],
          banner: "https://www.pangodgroup.com/static/bg7_1.png",
          bg: "https://www.pangodgroup.com/static/bg7.png",
          desc: "message.business.diandang.desc",
          href: "http://www.fududiandang.com",
        },
        {
          id: 5,
          business: "message.business.riben.title",
          logo: "https://www.pangodgroup.com/static/logo_riben.png",
          info: [
            "message.business.riben.info1",
            "message.business.riben.info2",
            "message.business.riben.info3",
          ],
          banner: "https://www.pangodgroup.com/static/bg9_1.png",
          bg: "https://www.pangodgroup.com/static/bg9.png",
          desc: "message.business.riben.desc",
          href: "http://www.pangod.jp",
        },
        {
          id: 6,
          business: "message.business.huanzong.title",
          logo: "https://www.pangodgroup.com/static/logo_huanzong.png",
          info: [
            "message.business.huanzong.info1",
            "message.business.huanzong.info2",
            "message.business.huanzong.info3",
            "message.business.huanzong.info4",
            "message.business.huanzong.info5",
          ],
          banner: "https://www.pangodgroup.com/static/bg10_1.png",
          bg: "https://www.pangodgroup.com/static/bg10.png",
          desc: "message.business.huanzong.desc",
        },
      ],
      nowCompany: {},
      swiper: null,
      activeSwiperIdx: 0,
      companyInfoShow: false,
      companyDetailShow: false,
      numIncrease: false, // 判断数字是否已开启过增长
      backShow: false,
      mapChoseShow: false,
      initMaps: [
        {
          name: "百度地图",
          url: "http://api.map.baidu.com/marker?location=104.121448,30.636383&title=成都盘古纵横集团&content=盘古纵横集团&output=html&src=成都盘古纵横集团",
        },
        {
          name: "高德地图",
          url: "https://uri.amap.com/marker?position=104.110697,30.634189&name=成都盘古纵横集团&callnative=1&src=成都盘古纵横集团",
        },
        {
          name: "腾讯地图",
          url: "https://apis.map.qq.com/uri/v1/marker?marker=coord:30.634211,104.110836;title=成都盘古纵横集团;addr=四川省成都市东大路318号IMP环球都汇广场37F&referer=成都盘古纵横集团",
        },
      ],
      finalMaps: [],
    };
  },
  components: { countTo, BackToTop },
  computed: {
    handleClass() {
      if (this.nowCompany.id % 2 === 0) {
        return "wrap-right";
      }
      return "wrap-left";
    },
  },
  watch: {
    "$i18n.locale"(val) {
      this.handleMapOptions(val);
    },
  },
  created() {
    this.nowLang = this.languages.find((ele) => ele.value == this.$i18n.locale);
    this.handleMapOptions(this.$i18n.locale);
  },
  mounted() {
    this.handleShouldScroll();

    setTimeout(() => {
      this.computedSafeScreen();
      this.initPageSwiper();
    }, 100);

    window.addEventListener("resize", this.computedSafeScreen);
  },
  methods: {
    // 判断首页的详情部分是否可以下滑
    handleShouldScroll() {
      let scroll = document.getElementById("scroll");
      let [startY, moveEndY] = [0, 0, 0, 0];
      scroll.ontouchstart = (e) => {
        // 用户触碰滚动文字时默认不触发swiper滚动，开始滚动时再进行判断是否触发
        startY = e.targetTouches[0].pageY;
      };
      scroll.ontouchend = (e) => {
        let scrollTop = e.target.scrollTop; // 距顶部
        let clientHeight = e.target.clientHeight; // 可视区高度
        let scrollHeight = e.target.scrollHeight; // 滚动条总高度
        moveEndY = e.changedTouches[0].pageY;
        const Y = moveEndY - startY;
        const rule2 = scrollTop + clientHeight + 1 >= scrollHeight && Y < 0; // 触底时要下滑
        if (rule2) {
          // 触底后还要继续下滑则手动触发swiper滚动事件
          this.swiper.slideNext();
        }
      };
    },
    // 一屏网页，计算视口高度为实际展示高度，避免浏览器网址信息遮挡的情况发生
    computedSafeScreen() {
      let swiper = document.getElementById("swiper");
      let modal = document.getElementsByClassName("modal")[0];
      swiper.style.height = `${window.innerHeight}px`;
      modal.style.height = `${window.innerHeight}px`;
    },
    // 格式处理可选地图列表
    handleMapOptions(val) {
      if (val == "zh") {
        this.finalMaps = [...this.initMaps];

        if (navigator.userAgent.indexOf("iPhone") != -1) {
          // 苹果端加入一个苹果地图
          this.finalMaps.unshift({
            name: "苹果地图",
            url: "https://maps.apple.com/?q=四川省成都市东大路318号IMP环球都汇广场37F&sll=104.110697,30.634189&z=10&t=m",
          });
        }
      } else {
        // 国外同用谷歌地图
        this.finalMaps = [
          {
            name: "Google Maps",
            url: "https://maps.google.com/maps?q=104.11486,30.6306",
          },
        ];
      }
    },
    toTop() {
      this.swiper.slideTo(0);
    },
    openAction() {
      this.langShow = true;
    },
    // 切换语言
    langSelect(item) {
      this.nowLang = item;
      this.$i18n.locale = item.value;
      this.$forceUpdate();
      localStorage.setItem("lang", item.value);
      this.langShow = false;
    },
    initPageSwiper() {
      let vm = this;
      this.swiper = new window.Swiper("#swiper", {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd() {
            vm.backShow = this.activeIndex != 0;
            if (this.activeIndex == 1) {
              // 开始数字滚动
              for (let i = 0; i < vm.strength.length; i++) {
                vm.$refs["count" + (i + 1)][0].start();
              }
            }
            if (navigator.userAgent.indexOf("iPhone") == -1) {
              // android端才会自动播放，iOS端因自动播放会全屏不够友好
              if (this.activeIndex == 4) {
                vm.$refs.video.play();
              } else {
                vm.$refs.video.pause();
              }
            }
          },
        },
      });
    },
    viewInfo(idx) {
      this.nowCompany = this.company[idx];
      this.companyInfoShow = true;
    },
    closeModal() {
      this.companyInfoShow = false;
      this.companyDetailShow = false;
    },
    // 地图选择跳转事件
    openMap() {
      this.mapChoseShow = true;
    },
    mapSelect(item) {
      window.location.href = item.url;
    },
    // 展示公司详情介绍弹窗
    viewAll() {
      this.companyDetailShow = true;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.computedSafeScreen);
  },
};
</script>

<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}
@keyframes mouseMove {
  20% {
    transform: translate(-50%, -5px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}
@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
.page-en {
  .infoModal .wrap .content .desc {
    text-align: left;
  }
  .wrap-left,
  .wrap-right {
    .wrap .content .desc {
      letter-spacing: 1px;
      text-indent: 0;
    }
  }
}
.page-en,
.page-ja {
  .box1 .intro {
    --left: 20px;

    .desc .text {
      width: 40vw;
      text-indent: 0;
    }
  }
  .box3 {
    .title {
      margin-bottom: 5px;
    }
    .wrap .item {
      width: 35vw;
      height: 35vw;
    }
  }
  .box4 {
    .title {
      margin: 4vh auto 3vh;
    }
    .wrap {
      margin-top: 10px;

      .item {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        .top {
          margin-bottom: 7px;
        }
        .infoes {
          flex-grow: 1;
          flex-direction: column;
          justify-content: space-evenly;

          .info {
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .box11 .content {
    padding: 0 5%;

    .item {
      margin-top: 8vh;

      .title {
        margin-bottom: 10px;
      }
    }
    .second,
    .third {
      margin-top: 5vh;
    }
  }
}
.page-ja {
  .box1 .intro .desc {
    font-size: 13px;
    text-indent: 1em;
    padding-right: 4px;
  }
  .wrap-left,
  .wrap-right {
    .wrap .content .desc {
      text-indent: 1em;
    }
  }
}
.modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }

  .close {
    font-size: 18px;
    color: #333;
    position: absolute;
    top: 20px;
    right: 25px;
    animation: scaleIn 0.1s linear;

    &:hover {
      color: #e3792a;
      cursor: pointer;
    }
  }

  .modal-body {
    width: 88vw;
    border-radius: 25px;
    background-color: #fff;
    animation: scaleIn 0.1s linear;
    position: relative;
    z-index: 999;
  }
}
.infoModal {
  .wrap .content .desc {
    max-height: 60vh !important;
    font-size: 14px !important;
  }
  .title .name {
    font-size: 16px !important;
  }
}

.nowlang {
  font-size: 14px;
  color: #333;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 99;

  .lang_icon {
    margin-right: 5px;
  }
}
.lang_icon {
  width: 20px;
  vertical-align: middle;
}
.lang-content {
  color: #323233;
  text-align: center;

  .option {
    padding: 14px 16px;

    &:active {
      background-color: #f2f3f5;
    }

    .in {
      width: 46px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .gap {
    height: 8px;
    background-color: #f7f8fa;
  }
  .cancel {
    padding: 14px 16px;
    color: #646566;

    &:active {
      background-color: #f2f3f5;
    }
  }
}

.page_logo {
  width: 100px;
  position: absolute;
  left: 2%;
  top: 2%;
  z-index: 10;
}

#swiper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  ::v-deep .swiper-pagination-bullet {
    height: 30px;
    width: 3px;
    border-radius: 13px;
  }
}

.box1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.banner {
  height: 30vh;
  position: relative;
  background-image: url("https://www.pangodgroup.com/static/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .content {
    width: 90%;
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    margin: auto;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(44, 127, 224, 0.38),
      transparent
    );
    padding: 10px 0;
    letter-spacing: 2px;

    .title {
      color: transparent;
      background-image: linear-gradient(
        337deg,
        #fff 38%,
        rgba(246, 164, 86, 0.8) 51%,
        #fff 60%
      );
      background-clip: text;
      -webkit-background-clip: text;
      position: relative;
      z-index: 4;
    }
    .shadow {
      width: 100%;
      color: #333;
      opacity: 0.2;
      text-align: center;
      background-image: none;
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: -1;
    }
    .title-en .title {
      font-size: 0.32rem;
      line-height: 1.1;
    }
    .line {
      width: 50%;
    }
    .sub-box {
      transition-delay: 0.5s;
    }
    .sub {
      color: transparent;
      background-image: repeating-linear-gradient(
        337deg,
        #fff 10%,
        rgba(246, 164, 86, 0.8) 22%,
        #fff 60%,
        #fff 80%
      );
      background-clip: text;
      -webkit-background-clip: text;
      transition-delay: 0.5s;
    }
    .sub-shadow {
      width: 100%;
      color: #333;
      opacity: 0.2;
      text-align: center;
      background-image: none;
      position: absolute;
      left: 2px;
      bottom: -2px;
      z-index: -1;
    }
    .sub-en .sub {
      font-size: 0.3rem;
      line-height: 1.1;
    }
  }

  .banner-title {
    width: 60%;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

.box1 .intro {
  height: calc(100% - 30vh);
  background-image: url("https://www.pangodgroup.com/static/m/box1-1-1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  --left: 2vh;
  padding: var(--left);
  box-sizing: border-box;

  .intro-left {
    height: 100%;
  }

  .title {
    width: 100%;
    font-size: 20px;
    color: #34629f;
    margin-bottom: 5vh;
    font-weight: bold;

    &::after {
      content: "";
      width: 50px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: -15px;
      background-color: #34629f;
    }
  }

  .desc {
    height: calc(100% - 5vh - 28px);
    overflow: auto;
    color: #696868;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 22px;
    transition-delay: 0.5s;
    text-align: justify;
    text-indent: 2em;

    .btn {
      width: fit-content;
      padding: 6px 16px;
      margin: 20px 0 0 auto;
      font-size: 13px;
      text-align: center;
      color: #fff;
      background-color: #5b76a3;
      position: relative;
      font-weight: 600;
    }
  }

  .desc-en {
    font-size: pxToRem(10);
    line-height: 18px;
    text-align: left;
    text-indent: 0;
  }
}

.box2 {
  width: 100%;
  background-image: url("https://www.pangodgroup.com/static/m/page2_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .intro {
    padding: 20px 8vw;
    box-sizing: border-box;

    .title {
      color: #34629f;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 2px;

      .line {
        width: 40px;
        height: 3px;
        background-color: #34629f;
        margin-top: 10px;
      }
    }
    .desc {
      max-height: 45vh;
      overflow: auto;
      margin-top: 20px;
      text-align: justify;
      text-indent: 2em;
      color: #4f5355;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 1px;
      transition-delay: 0.5s;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 12;
      -webkit-box-orient: vertical;
    }
    .all {
      text-align: center;
      color: #007aff;
      font-size: 14px;
      margin-top: 5px;

      i {
        margin-left: 5px;
      }
    }
  }
  .intro-en {
    padding: 14px 8vw;

    .desc {
      text-align: left;
      margin-top: 10px;
      font-size: 13px;
      line-height: 20px;
      -webkit-line-clamp: 15;
    }
  }
}

.box3 {
  width: 100%;
  position: relative;

  .content {
    background-image: url("https://www.pangodgroup.com/static/m/page3_bg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      display: table;
    }
  }

  .bottom-bg {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .title {
    width: fit-content;
    color: #34629f;
    font-size: 20px;
    font-weight: bold;
    margin: 14% auto 30px;
    letter-spacing: 2px;

    .line {
      width: 40px;
      height: 3px;
      background-color: #34629f;
      margin: 10px auto 0;
    }
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 5vh;

    .item {
      width: 30vw;
      height: 30vw;
      margin: 0 3vw 20px;
      background-image: url("https://www.pangodgroup.com/static/m/page3_circle.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      &:nth-child(1) {
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        transition-delay: 0.4s;
      }
      &:nth-child(3) {
        transition-delay: 0.5s;
      }
      &:nth-child(4) {
        transition-delay: 0.6s;
      }

      .in {
        max-width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;

        .num {
          line-height: 1.2;
          font-size: 15px;
          letter-spacing: 2px;
          display: flex;
          align-items: flex-end;

          .count {
            font-size: 22px;
          }
        }
        .topUnit {
          align-items: flex-start;
        }
        .line {
          width: 50%;
          height: 1px;
          background-color: #fff;
          margin-top: 5px;
        }
        .label {
          font-size: 12px;
          margin-top: 5px;
          text-align: center;
        }
      }
    }
  }
}
.box4 {
  width: 100%;
  background-image: url("https://www.pangodgroup.com/static/m/page4_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &::before {
    content: "";
    display: table;
  }

  .in {
    background-image: url("https://www.pangodgroup.com/static/m/page4_modal.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 85vh;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .title {
    width: fit-content;
    color: #34629f;
    font-size: 20px;
    font-weight: bold;
    margin: 4vh auto 30px;
    letter-spacing: 2px;

    .line {
      width: 40px;
      height: 3px;
      background-color: #34629f;
      margin: 10px auto 0;
    }
  }

  .wrap {
    margin-top: 40px;
    padding: 0 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 49%;
      border: 1px solid #e3792a;
      border-radius: 4px;
      padding: 8px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 4%;
      transition: 0.5s;
      font-size: 12px;

      .top {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      }
      .logo {
        width: 70px;
      }
      .more {
        width: 65px;
        height: 30px;
        font-size: 11px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        background-color: #286a97;
        user-select: none;

        &:active {
          transform: scale(0.9);
        }
      }
      .infoes {
        color: #2d2a2a;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .info {
          min-width: 40%;
          line-height: 10px;
          margin: 0 1%;
          margin-bottom: 10px;
          font-size: 0.18rem;
          text-align: center;
        }
      }
    }
  }
}

.wrap-left,
.wrap-right {
  width: 100%;
  height: 100%;
  padding: 20px 25px;
  box-sizing: border-box;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;

    .name {
      color: #e3792a;
      font-size: 14px;
      margin: 0 5px;
      letter-spacing: 2px;
    }

    .point {
      width: 15px;
    }
  }
  .wrap {
    margin-top: 15px;

    .logos {
      width: 100%;
    }
    .content {
      margin-top: 5px;

      .desc {
        max-height: 30vh;
        overflow: auto;
        color: #717171;
        font-size: 12px;
        letter-spacing: 2px;
        text-indent: 2em;
        line-height: 18px;
        margin-bottom: 20px;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          width: 30vw;
        }
        .more {
          height: 30px;
          padding: 0 10px;
          display: block;
          line-height: 30px;
          color: #fff;
          background-color: #286a97;
          font-size: pxToRem(9);
          letter-spacing: 2px;
          text-align: center;
        }
      }
    }
  }
}

.box11 {
  width: 100%;
  background-image: url("https://www.pangodgroup.com/static/m/page11_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 6%;

    .item {
      text-align: center;
      margin-top: 12vh;

      .title {
        font-size: 20px;
        letter-spacing: 7px;
      }
      .line {
        width: 30px;
        height: 2px;
        margin: 10px auto;
        background-color: #e3792a;
      }
      .subs {
        font-size: 0.28rem;
        line-height: 25px;
        letter-spacing: 1px;
      }
    }
    .second {
      margin-top: 7vh;
      transition-delay: 0.4s;
    }
    .third {
      margin-top: 7vh;
      transition-delay: 0.7s;
    }
  }
}

.box12 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .half {
    height: 55vh;
    background-image: url("https://www.pangodgroup.com/static/m/page12_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &::before {
      content: "";
      display: table;
    }
  }

  .title {
    color: #34629f;
    font-size: pxToRem(19);
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 13%;

    .line {
      width: 40px;
      height: 3px;
      background-color: #34629f;
      margin: 10px auto 0;
    }
  }
  .content {
    margin: 30px auto 0;
    padding: 0 20px;

    video {
      width: 100%;
    }
  }
  .contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::before {
      content: "";
      display: table;
    }

    &-title {
      width: 140px;
      height: 40px;
      margin: 20px auto 10px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      background-color: #5b76a3;
    }
  }
  .content-box {
    background-color: #ede1d9;
    text-align: center;

    .left {
      padding: 10px 10px;

      .addr {
        padding: 7px 0;
        display: block;

        & + .addr {
          transition-delay: 0.5s;
          border-top: 1px solid #9dafd0;
        }
        &:last-child {
          transition-delay: 0.8s;
        }

        .icon {
          width: 20px;
        }
        .info {
          margin-top: 3px;

          .detail {
            color: #2a2929;
            font-size: 14px;

            span,
            a {
              color: #6e6e6e;
            }
          }
        }
      }
    }
    .foot {
      padding: 5px 0;
      color: #f0efef;
      font-size: 12px;
      background-color: #86a1cd;

      div + div {
        margin-top: 5px;
      }

      a {
        color: #f0efef;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.fadeInUp {
  transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
  transform: translateY(10px);
  opacity: 0;
}
.fadeIn {
  transition: opacity 0.5s 0.2s;
  opacity: 0;
}
.fadeInRight {
  transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
  transform: translateX(20px);
  opacity: 0;
}
.fadeInLeft {
  transition: transform 0.5s 0.2s, opacity 0.5s 0.2s;
  transform: translateX(-20px);
  opacity: 0;
}

.swiper-slide-active {
  .fadeInUp {
    transform: translateY(0);
    opacity: 1;
  }
  .fadeIn {
    opacity: 1;
  }
  .fadeInRight,
  .fadeInLeft {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
